<template>
    <div class="aa-ofed">
        <div class="aa-ofed-titulo aa-text-h2">
            <span class="text-start"> OFERTA</span>
            <span class="text-start"> EDUCATIVA</span>
        </div>
        <div class="aa-ofed-img">
        </div>
        <p class="aa-ofed-txt text-start ps-1">
            Nuestro centro hace una apuesta al completo por el futuro de los jóvenes de Morón, ofertando
            todos los niveles de enseñanza secundaria
        </p>
    </div>
    <div class="aa-text-h1 my-1 text-start ms-1">
        Opciones
    </div>
    <div class="aa-ofed-list mb-3">
    <oferta-card v-for="oferta in ofertasList" :key="oferta.id" :datos="oferta" />
    
    <router-link :to="{name:'bilinguismo'}">
    <div class="aa-oferta-card-home" :style="'background-image: url('+bilingue.portada+')'" v-if="bilingue">
        <div class="aa-oferta-card-home-text">
            <span class="aa-text-h2 text-start d-block text-uppercase ps-1 pb-1">
                {{bilingue.nombre}}
            </span>
        </div>
    </div>
    </router-link>
    </div>

</template>


<script>
import useOfertas from '../composables/useOfertas'
import { defineAsyncComponent } from '@vue/runtime-core'
import useBilin from '../composables/useBilin'
export default {
    components: {
        OfertaCard: defineAsyncComponent( () => import('../components/Home/OfertaCard.vue') )
    },
    setup(){
        const { isLoading,cargaOfertas, ofertasList } = useOfertas()
        const { getBasico, bilingue } = useBilin()
        
        getBasico()
        cargaOfertas()
        
        return{
            isLoading,
            ofertasList,
            bilingue
        }
    }
}
</script>


<style lang="sass" scoped>
.aa-ofed
    $root:&
    position: relative
    margin-top: 96px
    &-titulo
        font-weight: 700 !important
        margin-top: 20%
        position: absolute
        @include breakpoint(md)
            margin-top: 80px
        span
            color: #fff
            display: block
            background-color: #18d580
            padding-left: 30px
            padding-right: 10px
            margin-bottom: 5px
    &-img
        background-image: url('../../../assets/img/oferta.jpg')
        width: 100%
        height: 300px
        height: 270px
        background-size: cover
        background-blend-mode: darken
        background-color: #00000080
    &-txt
        position: absolute
        top: 160px
        color: #fff
    &-list
        @include breakpoint(md)
            display: flex
            flex-direction: row
            flex-wrap: wrap
            gap: 60px
            justify-content: flex-start
            margin-inline-start: 30px
            margin-inline-end: 30px
            a
                width: 300px
</style>